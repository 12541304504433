import React from 'react';


const Introduction = () => {
  return (
    <section className="introduction">
      <div className="intro-text">
        <h2>Over onze Casa Buenaventura</h2>
      <p>

      Welkom bij Casa Buenaventura meer dan een huis!

We zijn blij dat je interesse hebt in onze vakantievilla, we hopen dat je net als wij een beetje verliefd wordt op ons huis en op de Costa Brava.

Geniet van het panoramische zeezicht op de baai van Palamos vanaf het grote zonovergoten terras. Neem een verfrissende duik in het zwembad en daarna een buitendouche.

Speel jeu de boules, heb goede gesprekken op de rustieke stenenbank met uitzicht op zee.

Lunch in de schaduw van de moerbeiboom en doe een drankje in de buitenkamer als de lichtjes van Palamos oplichten. 

Onze deuren staan voor je open, wij willen dat je je thuis voelt en met een glimlach terugdenkt aan deze dagen. We hebben er alles aan gedaan om dit mogelijk te maken.
      </p>

        {/* <p>


</p> */}
      </div>
      
    </section>
  );
}

export default Introduction;
