import React from 'react';
import '../App.css'; // Zorg ervoor dat dit pad correct is

const informationData = [
  { title: 'Slaapkamer 1', description: 'Airco \n1 2-persoonsbed', icon: '🛏️' },
  { title: 'Slaapkamer 2', description: 'Airco \n1 2-persoonsbed', icon: '🛏️' },
  { title: 'Slaapkamer 3', description: 'Airco \n2 1-persoonsbedden\nEnsuite Badkamer', icon: '🛏️' },
  { title: 'Studio', description: 'Airco \n 1 2-persoonsbed\nEnsuite badkamer', icon: '🛏️' },
  { title: 'Woonkamer', description: 'Airco \n Bank \n Eettafel \n TV \n Openhaard', icon: '🛋️'},
  { title: 'Badkamer 1', description: 'Inloopdouche\nToilet\nWasbak', icon: '🚿' },
  { title: 'Badkamer 2', description: 'Inloopdouche\nToilet\nWasbak', icon: '🚿' },
  { title: 'Badkamer 3', description: 'Inloopdouche\nToilet\nWasbak', icon: '🚿' },
  { title: 'Zwembad', description: 'Inbouw\n1.80 diep\nVerlicht', icon: '🏊' },
];

const Information = () => {
  return (
    <div className="information section">
      <h2>Indeling</h2>
      <div className="information-cards">
        {informationData.map((item, index) => (
          <div key={index} className="card">
            <div className="card-icon">{item.icon}</div>
            <div className="card-title">{item.title}</div>
            <div className="card-description">
              {item.description.split('\n').map((line, i) => (
                <p key={i}>{line}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Information;
