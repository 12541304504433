import React from 'react';

const Footer = ({ averageRating = 0 }) => {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} Casa Buenaventura</p>
      <p>Geïnteresseerd? Stuur een mailtje naar: <a href="mailto:boekingen@casabuenaventura.nl">boekingen@casabuenaventura.nl</a></p>
      <p>Gemiddelde beoordeling: {averageRating.toFixed(1)} sterren</p>
    </footer>
  );
}

export default Footer;
